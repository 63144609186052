<template>
	<section
		class="block"
		:class="[`block--${blockModifier}`, { 'block--footer': isBlockFooter }]"
		:style="computedStyles"
	>
		<BlockBackground
			v-if="data.background"
			:overlay-opacity="data.background['overlay-opacity']"
			:type="data.background.current"
			:color="data.background.color"
			:src="backgroundSrc"
			:srcset="backgroundSrcSet"
			:is-eager="lcp.type === 'block-background' && lcp.id === id"
			:is-fixed="data.attachment === 'fixed'"
			:alt="data.background && data.background.alt"
		/>
		<Component
			:is="data.type"
			:block-id="id"
			:data="data"
			:lcp="lcp"
			v-bind="{
				...$attrs,
				...$props
			}"
			:blocks="siteBlocks"
		/>
	</section>
</template>

<script>
import {
	computed,
	defineComponent,
} from 'vue';
import {
	mapState,
	useStore,
} from 'vuex';

import BlockBackground from '@zyro-inc/site-modules/components/blocks/BlockBackground.vue';
import BlockBlogHeader from '@zyro-inc/site-modules/components/blocks/blog/BlockBlogHeader.vue';
import {
	BLOCK_MODIFIERS_MAP,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants';
import { DEFAULT_BLOCK_GRID_STYLES } from '@zyro-inc/site-modules/constants/defaultStyles';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';
import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import { getMapValue } from '@zyro-inc/site-modules/utils/object';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { parseCSSSides } from '@zyro-inc/site-modules/utils/parseCSSSides';
import BlockGrid from '@/components/block-grid/BlockGridUser.vue';
import BlockLayout from '@/components/block-layout/BlockLayoutProviderUser.vue';
import BlockBlogList from '@/components/blocks/BlockBlogListProviderUser.vue';
import BlockEcommerceProductList from '@/components/blocks/BlockEcommerceProductListProviderUser.vue';
import BlockEcommerceProduct from '@/components/blocks/BlockEcommerceProductProviderUser.vue';
import BlockEcwidStore from '@/components/blocks/BlockEcwidStoreProviderUser.vue';
import BlockSlideshow from '@/components/blocks/BlockSlideshowProviderUser.vue';

const MOBILE_FULL_HEIGT_THRESHOLD = 360;

export default defineComponent({
	name: 'BlockUser',

	components: {
		BlockGrid,
		BlockLayout,
		BlockBackground,
		BlockBlogList,
		BlockBlogHeader,
		BlockEcwidStore,
		BlockSlideshow,
		BlockEcommerceProduct,
		BlockEcommerceProductList,
	},

	inheritAttrs: false,

	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
		currentLocale: {
			type: String,
			default: SYSTEM_LOCALE,
		},
		style: {
			type: Object,
			default: () => ({}),
		},
	},

	setup(props) {
		const { state } = useStore();

		const computedStyles = computed(() => {
			const useBlockGridStyles = props.data.type === 'BlockGrid'
				|| props.data.type === 'BlockSlideshow';

			return {
				...props.style,
				...(useBlockGridStyles ? DEFAULT_BLOCK_GRID_STYLES : {}),
				...objectToCssVariables(props.data?.settings?.styles),
			};
		});
		const isMobileFullScreen = computed(() => {
			if (!props.data?.settings?.styles['m-block-padding']) {
				return false;
			}

			/**
			 * This is an assumption that if user sets large paddings on mobile,
			 * he might want to have either full screen or height background.
			 */
			const {
				top,
				bottom,
			} = parseCSSSides(props.data.settings.styles['m-block-padding']);
			const mobilePadding = Number.parseInt(top, 10) + Number.parseInt(bottom, 10);

			return mobilePadding > MOBILE_FULL_HEIGT_THRESHOLD;
		});

		const blockModifier = computed(() => getMapValue(props.data.type, BLOCK_MODIFIERS_MAP));

		const backgroundSrc = computed(() => getOptimizedSrc(
			props.data.background.origin,
			props.data.background.path,
			state.website.siteId, {
				width: FULL_WIDTH,
			},
		));

		const backgroundSrcSet = computed(
			() => getFullWidthSrcset(
				props.data.background.origin,
				props.data.background.path,
				state.website.siteId, {
					isMobileFullScreen: isMobileFullScreen.value,
				},
			),
		);

		const isBlockFooter = computed(() => props.data.slot === 'footer');

		const siteBlocks = computed(() => state.website.languages[props.currentLocale].blocks);

		return {
			blockModifier,
			computedStyles,
			backgroundSrc,
			backgroundSrcSet,
			isBlockFooter,
			siteBlocks,
		};
	},

	computed: {
		...mapState(['website']),
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/components/Block";
</style>
