<template>
	<BlockLayoutWrapper
		ref="blockLayout"
		:style="layoutCSSVars"
		:is-mobile-legacy="isMobileLegacy"
	>
		<LayoutElementProviderUser
			v-for="element in layoutElements"
			:key="element.elementId"
			:is-mobile-legacy="isMobileLegacy"
			:element-id="element.elementId"
			:element-data="element"
			:lcp="lcp"
		/>
	</BlockLayoutWrapper>
</template>

<script>
import {
	toRefs,
	defineComponent,
} from 'vue';

import BlockLayoutWrapper from '@zyro-inc/site-modules/components/blocks/layout/BlockLayoutWrapper.vue';
import { useBlockLayout } from '@zyro-inc/site-modules/components/blocks/layout/useBlockLayout';
import LayoutElementProviderUser from '@/components/layout-element/LayoutElementProviderUser.vue';

export default defineComponent({
	name: 'BlockLayoutProviderUser',

	components: {
		BlockLayoutWrapper,
		LayoutElementProviderUser,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		components: {
			type: Object,
			default: () => ({}),
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
	},

	setup(props) {
		const { components: siteElements } = toRefs(props);

		const {
			layoutElements,
			layoutCSSVars,
			isMobileLegacy,
		} = useBlockLayout(props, siteElements);

		return {
			layoutElements,
			isMobileLegacy,
			layoutCSSVars,
		};
	},
});
</script>
