<template>
	<LayoutElementWrapper
		class="layout-element"
		:element-data="elementData"
		:is-mobile-legacy="isMobileLegacy"
	>
		<Component
			:is="elementData.type"
			:id="elementId"
			v-qa="`${elementData.type}:${elementId}`"
			:lcp="lcp"
			:data="elementData"
			:class="['layout-element__component', `layout-element__component--${elementData.type}`]"
			:element-width="elementData.desktop.width"
			:element-height="elementData.desktop.height"
			:mobile-element-width="elementData.mobile.width"
			:mobile-element-height="elementData.mobile.height"
			:reset-mobile-position="isMobileLegacy"
		/>
		<slot />
	</LayoutElementWrapper>
</template>

<script>
import LayoutElementWrapper from '@zyro-inc/site-modules/components/blocks/layout/LayoutElementWrapper.vue';
import GridButtonProviderUser from '@/components/elements/GridButtonProviderUser.vue';
import GridEcommerceButtonProviderUser from '@/components/elements/GridEcommerceButtonProviderUser.vue';
import GridEmbedProviderUser from '@/components/elements/GridEmbedProviderUser.vue';
import GridFormProviderUser from '@/components/elements/GridFormProviderUser.vue';
import GridGalleryProviderUser from '@/components/elements/GridGalleryProviderUser.vue';
import GridImageProviderUser from '@/components/elements/GridImageProviderUser.vue';
import GridInstagramFeedProviderUser from '@/components/elements/GridInstagramFeedProviderUser.vue';
import GridMapProviderUser from '@/components/elements/GridMapProviderUser.vue';
import GridSocialIconsProviderUser from '@/components/elements/GridSocialIconsProviderUser.vue';
import GridStripeButtonProviderUser from '@/components/elements/GridStripeButtonProviderUser.vue';
import GridTextBoxProviderUser from '@/components/elements/GridTextBoxProviderUser.vue';
import GridVideoProviderUser from '@/components/elements/GridVideoProviderUser.vue';
import GridShapeProviderUser from '@/components/elements/GridShapeProviderUser.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LayoutElementProviderUser',

	components: {
		GridButton: GridButtonProviderUser,
		GridStripeButton: GridStripeButtonProviderUser,
		GridEcommerceButton: GridEcommerceButtonProviderUser,
		GridForm: GridFormProviderUser,
		GridVideo: GridVideoProviderUser,
		GridTextBox: GridTextBoxProviderUser,
		GridMap: GridMapProviderUser,
		GridSocialIcons: GridSocialIconsProviderUser,
		GridGallery: GridGalleryProviderUser,
		GridImage: GridImageProviderUser,
		GridInstagramFeed: GridInstagramFeedProviderUser,
		GridEmbed: GridEmbedProviderUser,
		GridShape: GridShapeProviderUser,
		LayoutElementWrapper,
	},

	props: {
		elementId: {
			type: String,
			required: true,
		},
		elementData: {
			type: Object,
			required: true,
		},
		overrideWidth: {
			type: Number,
			default: null,
		},
		overrideHeight: {
			type: Number,
			default: null,
		},
		isMobileLegacy: {
			type: Boolean,
			default: false,
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
	},
});
</script>

<style lang="scss">
@import "@zyro-inc/site-modules/components/blocks/layout/LayoutElementWrapperProvider";
</style>
