<template>
	<Transition name="fade">
		<div class="modal-wrapper">
			<div
				tabindex="-1"
				class="modal-backdrop"
				@click="handleCloseModalAction"
			/>
			<div
				ref="modal"
				:style="modalStyle"
				class="modal"
				tabindex="0"
				aria-modal="true"
				@keydown.esc="handleCloseModalAction"
			>
				<CloseButton
					class="modal__close-button"
					@click="handleCloseModalAction"
				/>
				<slot />
			</div>
		</div>
	</Transition>
</template>

<script>
import CloseButton from '@zyro-inc/site-modules/components/CloseButton.vue';
import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		CloseButton,
	},

	props: {
		backgroundColor: {
			type: String,
			default: '#fff',
		},
		padding: {
			type: String,
			default: '16px',
		},
		width: {
			type: String,
			default: 'auto',
		},
		maxWidth: {
			type: String,
			default: 'unset',
		},
	},

	setup() {
		const { closeEcommerceModal } = useEcommerceModal();

		return {
			closeEcommerceModal,
		};
	},

	computed: {
		modalStyle() {
			return {
				'--background-color': this.backgroundColor,
				'--padding': this.padding,
				'--width': this.width,
				'--max-width': this.maxWidth,
			};
		},
	},

	methods: {
		handleCloseModalAction() {
			this.closeEcommerceModal();

			// Assigning undefined to a query parameter removes it from the parameters list completely
			this.$router.replace({
				query: {
					...this.$route.query,
					'open-modal': undefined,
					product: undefined,
				},
			}).catch((error) => {
				if (error.name !== 'NavigationDuplicated') throw error;
			});
		},
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.modal-wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: $z-index-user--modal-overlay;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	transform: translate(-50%, -50%);

	@include site-engine-mobile {
		padding: 0 16px;
	}
}

.modal-backdrop {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	background-color: rgba($dark, 0.5);
}

.modal {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: var(--width);
	min-width: 540px;
	max-width: var(--max-width);
	padding: var(--padding);
	font-family: var(--font-primary);
	text-align: center;
	background-color: var(--background-color);
	outline: none;

	@include site-engine-mobile {
		min-width: unset;
	}

	&__close-button {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 1;
		align-self: flex-end;
	}
}
</style>
