export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
export const THEMES = [
	THEME_LIGHT,
	THEME_DARK,
];
export const MINIMUM_LOAD_TIME = 200;
export const SUBMIT_ACTIONS = {
	SHOW_MESSAGE: 'showMessage',
	LINK_TO_PAGE: 'linkToPage',
};
