<template>
	<GridShape
		:svg="data.svg"
		:style="cssVars"
	/>
</template>

<script>
import GridShape from '@zyro-inc/site-modules/components/elements/shape/GridShape.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'GridShapeProviderUser',

	components: {
		GridShape,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	computed: {
		cssVars() {
			return {
				'--shape-height': `${this.data.desktop.height}px`,
				'--m-shape-height': `${this.data.mobile.height}px`,
				'--shape-color': `${this.data.color}`,
			};
		},
	},
});
</script>
