import { computed } from 'vue';

export const useGridGallery = (props) => {
	const hasGridImages = computed(() => props.data.images.length > 0);
	const columnCount = computed(() => Number.parseInt(props.data.settings.styles['column-count'], 10));
	const columnGap = computed(() => Number.parseInt(props.data.settings.styles['column-gap'], 10));
	const isMasonryLayout = computed(() => props.data.settings.layout === 'masonry');

	return {
		hasGridImages,
		columnCount,
		columnGap,
		isMasonryLayout,
	};
};
