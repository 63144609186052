<template>
	<div
		:style="computedStyles"
		class="block-layout"
		:class="{
			'block-layout--legacy': isMobileLegacy,
			'block-layout--layout': !isMobileLegacy,
		}"
	>
		<slot />
	</div>
</template>
<script>
import {
	MOBILE_BLOCK_WIDTH,
	DESKTOP_BLOCK_WIDTH,
	MOBILE_BLOCK_PADDING_X,
} from '@zyro-inc/site-modules/components/blocks/layout/constants';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BlockLayoutWrapper',

	props: {
		isMobileLegacy: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		computedStyles() {
			return {
				'--m-max-width': `${MOBILE_BLOCK_WIDTH}px`,
				'--max-width': `${DESKTOP_BLOCK_WIDTH}px`,
				'--m-padding': `0 ${MOBILE_BLOCK_PADDING_X}px`,
			};
		},
	},
});
</script>
<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.block-layout {
	z-index: $z-index-user--block-grid;
	display: grid;
	grid-template-rows: var(--grid-template-rows);
	grid-template-columns: var(--grid-template-columns);
	width: 100%;
	max-width: var(--max-width);
	min-height: var(--block-min-height);
	margin: 0 auto;
}

@include site-engine-mobile {
	.block-layout {
		&--layout {
			grid-template-rows: var(--m-grid-template-rows);
			grid-template-columns: var(--m-grid-template-columns);
			max-width: var(--m-max-width);
			min-height: var(--m-block-min-height);
			padding: var(--m-padding);
		}

		// Render the elements in a flexbox column so we can map their positions for layout
		&--legacy {
			display: flex;
			flex-direction: column;
			grid-gap: 0;
			min-height: auto;
			padding: var(--m-block-padding);
		}
	}
}
</style>
