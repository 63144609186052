import {
	computed,
	ref,
} from 'vue';

import {
	DESKTOP_BLOCK_WIDTH,
	DEFAULT_MOBILE_BLOCK_HEIGHT,
	MOBILE_BUILDER_WIDTH,
} from '@zyro-inc/site-modules/components/blocks/layout/constants';

// Calculate grid-template-rows css property
export const calculateGridTemplateRows = (rowTopsUniqueSorted, buildResponsiveGrid) => {
	// For each distance from the top, subtract the previous distance to find distances between rows
	const gridRows = rowTopsUniqueSorted
		.map((rowTop, index, rowList) => (index === 0 ? rowTop : rowTop - rowList[index - 1]));

	const gridTemplateRows = `${gridRows
	// Remove 0 because CSS grid assumes 0 row by default
		.filter((row) => row !== 0)
	// Add 1fr at the end so grid reaches min-height without stretching rows
		.map((row) => {
			if (buildResponsiveGrid) {
				return `minmax(${row}px, auto)`;
			}

			return `${row}px`;
		}).join(' ')} 1fr`;

	return gridTemplateRows;
};

// Calculate vertical element positions
// eslint-disable-next-line consistent-return
export const calculateRowTopsUniqueSorted = (blockElements) => {
	try {
		// For each component, find the start and end distance from the top
		const rowsTop = blockElements
			.flatMap(({ position }) => [
				position.top,
				position.height + position.top,
			]);

		// Sometimes the start/end distances from the top are equal, so we need to remove duplicates
		// Adding 0 as the first row
		const rowTopsUniqueSorted = [
			...new Set([
				0,
				...rowsTop,
			]),
		].sort((a, b) => a - b);

		return rowTopsUniqueSorted;
	} catch (error) {
		console.error(error);
	}
};

// Calculate grid-template-rows css property
export const calculateGridTemplateColumns = (columnsLeftUniqueSorted, buildResponsiveGrid, blockWidth) => {
	// For each distance from the left, subtract the previous distance to find distances between columns
	const gridColumns = columnsLeftUniqueSorted
		.map((columnLeft, index, columnList) => (index === 0 ? columnLeft : columnLeft - columnList[index - 1]));

	const gridTemplateColumns = gridColumns
	// Remove 0 because CSS grid assumes 0 column by default
		.filter((column) => column !== 0)
		.map((column) => {
			if (buildResponsiveGrid) {
				return `${(column / blockWidth) * 100}%`;
			}

			return `${column}px`;
		}).join(' ');

	return gridTemplateColumns;
};

export const getElementPositionInGrid = (rowTopsUniqueSorted, columnsLeftUniqueSorted, elementPosition) => {
	const {
		top,
		left,
		width,
		height,
	} = elementPosition;

	const rowStart = rowTopsUniqueSorted.indexOf(top) + 1;
	const rowEnd = rowTopsUniqueSorted.indexOf(top + height) + 1;

	const columnStart = columnsLeftUniqueSorted.indexOf(left) + 1;
	const columnEnd = columnsLeftUniqueSorted.indexOf(left + width) + 1;

	return {
		rowStart,
		rowEnd,
		columnStart,
		columnEnd,
	};
};

// Calculate horizontal element positions
export const calculateColumnLeftsUniqueSorted = (blockElements, blockWidth) => {
	// For each component, find the start and end distance from the left
	const columnsLeft = blockElements
		.flatMap(({ position }) => [
			position.left,
			position.width + position.left,
		]);
	// Sometimes the start/end distances from the left are equal, so we need to remove duplicates
	// Adding 0 and block.width as the first and last column respectively
	const columnsLeftUniqueSorted = [
		...new Set([
			0,
			...columnsLeft,
			blockWidth,
		]),
	].sort((a, b) => a - b);

	return columnsLeftUniqueSorted;
};

export const useBlockLayout = (props, siteElements, shouldBuildResponsive = true) => {
	const buildResponsiveGrid = ref(shouldBuildResponsive);
	// Make an array of block components, extend it with componentId
	const blockElements = computed(() => props.data.components.map((elementId) => {
		const element = siteElements.value[elementId];

		if (element.mobile) {
			return {
				...element,
				elementId,
			};
		}

		const positionFromTop = props.data.components.indexOf(elementId);

		return {
			...element,
			mobile: {
				left: 0,
				width: MOBILE_BUILDER_WIDTH,
				top: positionFromTop * 16 + 16,
				height: -1,
			},
			elementId,
		};
	}));

	const isMobileLegacy = computed(() => props.data.components.some((element) => {
		const elementData = siteElements.value[element];

		return !elementData.mobile;
	}));

	// #region Desktop grid
	const elementPositionsDesktop = computed(() => blockElements.value.map((element) => ({
		...element,
		position: element.desktop,
	})));
	// Calculate grid-template-rows for desktop
	const desktopRowTopsUniqueSorted = computed(() => calculateRowTopsUniqueSorted(elementPositionsDesktop.value));
	const desktopGridTemplateRows = computed(() => calculateGridTemplateRows(desktopRowTopsUniqueSorted.value, buildResponsiveGrid.value));

	// Calculate grid-template-columns for desktop
	const desktopColumnsLeftUniqueSorted = computed(() => calculateColumnLeftsUniqueSorted(
		elementPositionsDesktop.value, DESKTOP_BLOCK_WIDTH,
	));
	const desktopGridTemplateColumns = computed(() => calculateGridTemplateColumns(
		desktopColumnsLeftUniqueSorted.value, buildResponsiveGrid.value, DESKTOP_BLOCK_WIDTH,
	));

	// #endregion

	// #region Mobile grid
	const elementPositionsMobile = computed(() => blockElements.value.map((element) => ({
		...element,
		position: element.mobile,
	})));

	// Calculate grid-template-rows for mobile
	const mobileRowTopsUniqueSorted = computed(() => calculateRowTopsUniqueSorted(elementPositionsMobile.value));
	const mobileGridTemplateRows = computed(() => calculateGridTemplateRows(mobileRowTopsUniqueSorted.value, buildResponsiveGrid.value));

	// Calculate grid-template-columns for mobile
	const mobileColumnsLeftUniqueSorted = computed(() => calculateColumnLeftsUniqueSorted(
		elementPositionsMobile.value, MOBILE_BUILDER_WIDTH,
	));
	const mobileGridTemplateColumns = computed(() => calculateGridTemplateColumns(
		mobileColumnsLeftUniqueSorted.value, buildResponsiveGrid.value, MOBILE_BUILDER_WIDTH,
	));
	// #endregion

	// Enhance block elements with grid-row property
	const layoutElements = computed(() => blockElements.value.map((element) => {
		const {
			rowStart,
			rowEnd,
			columnStart,
			columnEnd,
		} = getElementPositionInGrid(desktopRowTopsUniqueSorted.value, desktopColumnsLeftUniqueSorted.value, element.desktop);

		const {
			rowStart: mobileRowStart,
			rowEnd: mobileRowEnd,
			columnStart: mobileColumnStart,
			columnEnd: mobileColumnEnd,
		} = getElementPositionInGrid(mobileRowTopsUniqueSorted.value, mobileColumnsLeftUniqueSorted.value, element.mobile);

		return {
			...element,
			settings: {
				...element.settings,
				styles: {
					...(element.settings?.styles || {}),
					'z-index': props.data.zindexes.indexOf(element.elementId) + 1,
					'grid-row': `${rowStart}/${rowEnd}`,
					'grid-column': `${columnStart}/${columnEnd}`,
					'm-grid-row': `${mobileRowStart}/${mobileRowEnd}`,
					'm-grid-column': `${mobileColumnStart}/${mobileColumnEnd}`,
				},
			},
		};
	}));

	const layoutCSSVars = computed(() => ({
		'--grid-template-rows': desktopGridTemplateRows.value,
		'--grid-template-columns': desktopGridTemplateColumns.value,
		'--block-min-height': `${props.data.desktop.minHeight}px`,
		'--m-grid-template-rows': mobileGridTemplateRows.value,
		'--m-grid-template-columns': mobileGridTemplateColumns.value,
		'--m-block-min-height': `${props.data.mobile?.minHeight || DEFAULT_MOBILE_BLOCK_HEIGHT}px`,
	}));

	return {
		layoutElements,
		buildResponsiveGrid,
		layoutCSSVars,
		isMobileLegacy,
	};
};
